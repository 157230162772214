<template>
  <div class="history">
    <transition>
      <keep-alive include="index">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
export default {
  name: "history_day",
  data: function () {
    return {};
  },
  created() {},
};
</script>
<style>
.history {
  min-height: 100vh;
  background-color: #f7f5ef;
}
</style>